import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import TilesAndProcedures from "./Pages/TilesAndProcedures/TilesAndProcedures";
import About from "./Pages/About/About";
import PropertiesFAQ from "./Pages/PropertiesFAQ/PropertiesFAQ";
import CitizenshipFAQ from "./Pages/CitizenshipFAQ/CitizenshipFAQ";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Yourproperty from "./Pages/Yourproperty/Yourproperty";
import Plotdetail from "./Pages/Yourproperty/Plotdetail";
import Termofuse from "./Pages/Termsofuse/Termofuse";
import TokenClaim from "./Pages/TokenClaim/TokenClaim";
import NewLandingPage from "./Pages/NewLandingPage/NewLandingPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Certificate from "./Pages/Certificate/Certificate";

import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./utils/Web3Connection/connectors/walletConnectV2";
import {
  hooks as metaMaskHooks,
  metaMask,
} from "./utils/Web3Connection/connectors/metaMask";
import { Web3ReactProvider } from "@web3-react/core";
import useEagerConnect from "./hooks/Web3Connection/useEagerConnector";
const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

function App() {
  // useEagerConnect();
  return (
    <>
      <Web3ReactProvider connectors={connectors}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NewLandingPage />} />
            <Route path="/Home" element={<Home />} />

            <Route
              path="/TilesAndProcedures"
              element={<TilesAndProcedures />} 
            />
            <Route path="/About" element={<About />} />
            <Route path="/PropertiesFAQ" element={<PropertiesFAQ />} />
            <Route path="/CitizenshipFAQ" element={<CitizenshipFAQ />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termofuse" element={<Termofuse />} />
            <Route path="/yourproperty" element={<Yourproperty />} />
            <Route path="/plotdetail" element={<Plotdetail />} />
            <Route path="/LandingPg" element={<NewLandingPage />} />
            <Route path="/Certificate" element={ <Certificate /> } />
            <Route path="/TokenClaim" element={ <TokenClaim /> } />
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </>
  );
}

export default App;
